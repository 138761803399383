html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

strong {
  font-weight: 600;
}

html, body {
  background: none transparent;
}

.App {
  display: flex;
  flex-direction: column;
}

.CodeMirror {
  font-size: 12px;
  max-width: 505px;
}

#root {
  height: 100%;
}

.OptimizelyFrame {
  width: 430px;
  height: 280px;
}

.OptimizelyFrameBackground {
  width: 430px;
  height: 280px;
  position: absolute;
  z-index: -1;
}

.OptimizelyFrameContent {
  padding: 40px 10px 18px 10px;
}

.mover {
  -webkit-animation: move 1 0.5s ease-out;
          animation: move 1 0.5s ease-out;
}

.pulsater {
  -webkit-animation: pulsate infinite 300ms linear;
          animation: pulsate infinite 300ms linear;
}

.jiggler {
  -webkit-animation: jiggle 0.75s ease-in-out infinite alternate, fade-in 0.33s ease-in forwards;
          animation: jiggle 0.75s ease-in-out infinite alternate, fade-in 0.33s ease-in forwards;
  opacity: 0;
}

.bouncer {
  -webkit-animation: bounce 0.75s ease-in-out infinite alternate, fade-in 0.33s ease-in forwards;
          animation: bounce 0.75s ease-in-out infinite alternate, fade-in 0.33s ease-in forwards;
  opacity: 0;
}

.fader {
  -webkit-animation: fade-in 5s ease-in 1 1 forwards;
          animation: fade-in 5s ease-in 1 1 forwards;
}

.arrow-up {
  width: 0; 
  height: 0; 
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #0037FF;
}

.arrow-down {
  width: 0; 
  height: 0; 
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #0037FF;
}

.arrow-right {
  width: 0; 
  height: 0; 
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #0037FF;
}

.arrow-left {
  width: 0; 
  height: 0; 
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent; 
  border-right: 7px solid #0037FF; 
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1.0;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1.0;
  }
}

@-webkit-keyframes jiggle {
  0% {
    -webkit-transform: translate(0px,0);
            transform: translate(0px,0);
  }
  100% {
    -webkit-transform: translate(-10px,0);
            transform: translate(-10px,0);
  }
}

@keyframes jiggle {
  0% {
    -webkit-transform: translate(0px,0);
            transform: translate(0px,0);
  }
  100% {
    -webkit-transform: translate(-10px,0);
            transform: translate(-10px,0);
  }
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translate(0px,0);
            transform: translate(0px,0);
  }
  100% {
    -webkit-transform: translate(0px,10px);
            transform: translate(0px,10px);
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: translate(0px,0);
            transform: translate(0px,0);
  }
  100% {
    -webkit-transform: translate(0px,10px);
            transform: translate(0px,10px);
  }
}

@-webkit-keyframes pulsate {
  0% {
    color: #82B1FF;
    font-weight: inherit;
  }
  50% {
    color: #FFBF46;
    font-weight: bolder;
  }
  100% {
    color: #82B1FF;
    font-weight: inherit;
  }
}

@keyframes pulsate {
  0% {
    color: #82B1FF;
    font-weight: inherit;
  }
  50% {
    color: #FFBF46;
    font-weight: bolder;
  }
  100% {
    color: #82B1FF;
    font-weight: inherit;
  }
}

@-webkit-keyframes move {
  from {
    left: 0px;
    opacity: 1.0;
  }
  to {
    left: 100%;
    opacity: 1.0;
  }
}

@keyframes move {
  from {
    left: 0px;
    opacity: 1.0;
  }
  to {
    left: 100%;
    opacity: 1.0;
  }
}

.fade {
  transition: opacity 0.5s ease-out 0s;
}

.slide {
  transition: left 0.5s ease-out 0s, top 0.5s ease-out 0s;
}

@keyframes move {
  from {
    left: 0px;
    opacity: 1.0;
  }
  to {
    left: 100%;
    opacity: 1.0;
  }
}

